<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'admin.ocupational-safety.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="safeties_data"
              :sort-mapping="sortMapping"
              :title="'occupational safety and health'"
              :total="safetiesTotal"
              :fetch-path="'safeties/fetch'"
              :export-path="'safeties/export'"
              :filter-path="'safeties/filter'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    :href="$router.resolve({ name: 'admin.ocupational-safety.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="showDeleteModal(field.id)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Name</label>
                  <input
                    v-model="filters.name"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Name: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
                  >
                </div><div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Section or Subsection - WIP</label>
                  <input
                    v-model="filters.section"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Section or Subsection: ${$event.target.value}`, value: `${$event.target.value}` }, 'section')"
                  >
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'OSH',
          checked: true,
          order: 1,
        },
        {
          name: 'Name',
          checked: true,
          order: 2,
        },
        {
          name: 'Acronym',
          checked: true,
          order: 3,
        },
        // {
        //   name: 'Parent',
        //   checked: true,
        //   order: 4,
        // },
        {
          name: 'Visibility',
          checked: true,
          order: 5,
        },
      ],
      sortMapping: {
        OSH: 'table_order',
        Name: 'name',
        Acronym: 'code',
        // Parent: 'parent.name',
        Visibility: 'visibility_table',
      },
      title: 'Occupational Safety and Health',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      safeties: 'safeties/safeties',
      safetiesTotal: 'safeties/safetiesTotal',
      filters: 'filters/filters',
    }),
    safeties_data() {
      let aux = []
      if (this.safeties.length > 0) {
        this.safeties.forEach(item => {
          aux = aux.concat([item].concat(item.childrens))
        })
      }
      return aux
    },
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'ocupational_safety')
    await this.$store.dispatch('safeties/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'ocupational_safety',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'safeties', url: `${Vue.prototype.$groupUrl}/safeties/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
